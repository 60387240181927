import React from 'react';
import moment from 'moment';
const supervisorData = require('../../../common/data/supervisor_data.json');

class Supervisor {
  /**
   * TODO: Subclass a common Elected class and define term_length_years plus redefine staffemail
   *
   * @param {Object} person
   * @param {string} person.firstName
   * @param {string} person.lastName
   * @param {string} person.displayName
   * @param {string} person.phone
   * @param {bool} person.growSfEndorsed
   * @param {string} person.specialElection Date of the special election when this person was elected, if any. Null otherwise.
   * @param {string} person.elected Date of the regular election when this person was elected, if any. Null otherwise.
   * @param {string} person.reElected Date this person was most recently re-elected to their seat, if any. Null otherwise.
   * @param {string} person.appointed Date this person was appointed to their seat, if any. Null otherwise.
   * @param {string} person.nextElection Date of their next election, if any. Null otherwise.
   * @param {int} person.margin Margin by which they won their last election, if any. Null otherwise.
   * @param {string} person.type Type of elected, eg "Supervisor" or "Mayor"
   * @param {string} person.district District they represent.
   * @param {string} person.link Link to their content profile.
   */
  constructor(person = {}) {
    this.firstName = person.firstName;
    this.lastName = person.lastName;
    this.displayName = person.displayName;
    this.phone = person.phone;
    this.growSfEndorsed = person.growSfEndorsed;
    this._specialElection = person.specialElection;
    this._elected = person.elected;
    this._reElected = person.reElected;
    this._appointed = person.appointed;
    this._nextElection = person.nextElection;
    this._margin = person.margin;
    this.type = person.type;
    this.district = person.district;

    this._term_length_years = 4;
  }

  formatDate(dt) {
    return dt.format('MMMM YYYY');
  }

  parseDate(dts) {
    return moment(dts, 'MM/DD/YYYY');
  }

  /**
   * Was this person appointed?
   *
   * @returns {bool}
   */
  get wasAppointed() {
    return this._appointed !== null;
  }

  /**
   * When did this person take office?
   *
   * @return {date}
   */
  get tookOffice() {
    let dt = this._appointed || this._specialElection || this._elected;
    if (dt !== null) {
      return this.formatDate(this.parseDate(dt));
    }
    return null;
  }

  /**
   * Date of the special election, if any.
   *
   * @returns {date}
   */
  get specialElection() {
    if (this._specialElection !== null) {
      return this.formatDate(this.parseDate(this._specialElection));
    }
    return null;
  }

  /**
   * Date of the election, if any.
   *
   * @returns {date}
   */
  get elected() {
    if (this._elected !== null) {
      return this.formatDate(this.parseDate(this._elected));
    }
    return null;
  }

  /**
   * Date of the next election, if any.
   *
   * @returns {date}
   */
  get nextElection() {
    if (this._nextElection !== null) {
      return this.formatDate(this.parseDate(this._nextElection));
    }
    return null;
  }

  /**
   * If they were re-elected, return that date. Otherwise return null.
   *
   * @returns {date}
   */
  get reElected() {
    return this._reElected === null ? null : this.formatDate(this.parseDate(this._reElected));
  }

  /**
   * If they will be termed out in the next election, return the year of that election. Otherwise return null.
   *
   * @returns {date}
   */
  get termedOut() {
    if (this.reElected !== null) {
      return this.parseDate(this._reElected).year() + this._term_length_years;
    }
    return null;
  }

  /**
   * Get the staff email
   *
   * @returns {string}
   */
  get staffEmail() {
    return this.lastName.toLowerCase() + 'staff@sfgov.org';
  }

  /**
   * Get the elected email
   *
   * @returns {string}
   */
  get email() {
    return this.firstName + '.' + this.lastName + '@sfgov.org';
  }

  /**
   * Return a formatted election margin, if one exists
   *
   * @returns {string}
   */
  get margin() {
    if (this._margin !== null) {
      return this._margin.toLocaleString();
    }
    return null;
  }

  /**
   * Return a formatted link to their profile content
   *
   * @returns {string}
   */
  get slug() {
    return this.firstName.toLowerCase() + '-' + this.lastName.toLowerCase();
  }

  /** Get the elected full name
   *
   * @returns {string}
   */
  get fullName() {
    return this.firstName + ' ' + this.lastName;
  }

  /**
   * Get the elected real phone number
   *
   * @returns {string}
   */
  get getRealPhone() {
    return this.phone.replace(/[^0-9]+/g, '');
  }
}

// Filter People by type=='Supervisor' and return an object with each Supervisor's name as the key
export const SupervisorsByName = Object.fromEntries(
  Object.values(supervisorData)
    .filter((person) => person.type === 'Supervisor')
    .map((person) => [person.firstName + ' ' + person.lastName, new Supervisor(person)])
);
