import React, { Fragment, memo } from 'react';
import loadable from '@loadable/component';
const Table = loadable(() => import('../layout/table.js'));
//import { Bold, YesIcon, NoIcon } from '../helpers';
import YesIcon from '../icons/YesIcon';
import RankIcon from '../icons/RankIcon';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

export const AllCandidates = memo(
  ({
    candidates,
    party = true,
    profession = true,
    questionnaire = true,
    infoOnly = false,
    lang = 'en',
  }) => {
    const candidateStr = {
      en: 'Candidate',
      zh: '候選人',
      es: 'Candidato',
      fil: 'Kandidato',
    };
    const partyStr = {
      en: 'Party',
      zh: '政黨',
      es: 'Partido',
      fil: 'Partido',
    };
    const professionStr = {
      en: 'Profession',
      zh: '職業',
      es: 'Profesión',
      fil: 'Propesyon',
    };
    const questionnaireStr = {
      en: 'Questionnaire',
      zh: '問卷',
      es: 'Cuestionario',
      fil: 'Kwestyonaryo',
    };
    const readItStr = {
      en: 'Read it',
      zh: '閱讀',
      es: 'Léelo',
      fil: 'Basahin ito',
    };

    const anyEndorsed = candidates.some((candidate) => candidate.endorsed);
    let tableHeaders = [];
    if (anyEndorsed && !infoOnly) {
      tableHeaders.push({
        Header: '',
        accessor: 'endorsed',
      });
    }
    tableHeaders.push({
      Header: candidateStr[lang],
      accessor: 'candidate',
    });
    if (party) {
      tableHeaders.push({
        Header: partyStr[lang],
        accessor: 'party',
      });
    }
    if (profession) {
      tableHeaders.push({
        Header: professionStr[lang],
        accessor: 'profession',
      });
    }
    if (questionnaire) {
      tableHeaders.push({
        Header: questionnaireStr[lang],
        accessor: 'questionnaire',
      });
    }

    const getName = (candidate) => {
      let name1 = '';
      let name2 = '';
      if (lang == 'en') {
        name1 = candidate.names['en'] || candidate.name;
        name2 = candidate.names['zh'] || '';
      } else {
        name1 = candidate.names[lang] || candidate.name;
        name2 = candidate.names['en'] || '';
      }
      return (
        <div className="whitespace-nowrap">
          {candidate.endorsed ? <strong>{name1} </strong> : name1}
          <br />
          {name2 != name1 && <span className="text-sm font-light">{name2}</span>}
        </div>
      );
    };

    const endorsedStr = (candidate) => {
      if (candidate.endorsed) {
        if (Number.isInteger(candidate.endorsed)) {
          return (
            <div className="w-7 h-7">
              <RankIcon rank={candidate.endorsed} />
            </div>
          );
        } else {
          return (
            <div className="w-7 h-7">
              <YesIcon />
            </div>
          );
        }
      }
      return null;
    };

    const translate = (item, lang) => {
      if (lang == 'en') {
        return item['en'] || item;
      } else {
        return item[lang] || item['en'] || item;
      }
    };
    const tableContent = candidates.map((candidate) => ({
      endorsed: endorsedStr(candidate),
      candidate: getName(candidate),
      party: translate(candidate.party, lang),
      profession: translate(candidate.profession, lang),
      questionnaire:
        typeof candidate.questionnaire === 'string' &&
        candidate.questionnaire.startsWith('http') ? (
          <OutboundLink className="text-blue-600 underline" href={candidate.questionnaire}>
            {readItStr[lang]}
          </OutboundLink>
        ) : (
          candidate.questionnaire[lang]
        ),
    }));
    const whosRunningStr = {
      en: "Who's running?",
      zh: '誰在競選？',
      es: '¿Quién está corriendo?',
      fil: 'Sino ang tumatakbo?',
    };
    return (
      <div>
        <h4>{whosRunningStr[lang]}</h4>
        <Table tableData={tableContent} columnData={tableHeaders} />{' '}
        <p className="!text-sm italic">
          Candidates are listed in the same order as they appear on the ballot.
        </p>
      </div>
    );
  }
);
