import React from 'react';
import { Helmet } from 'react-helmet';

export const VoteFAQ = ({}) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "Can I update my voter registration online?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "<p>Most people can update their voter registration online. If you have a California driver's license you can update your voter registration or register to vote online with no paper forms.</p>><p><a href=https://growsf.org/vote/>Click here</a> to get started.</p>"
            }
          }, {
            "@type": "Question",
            "name": "Do I need to update my voter registration after I move?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, you must update your voter registration every time you move."
            }
          }, {
            "@type": "Question",
            "name": "Can I register to vote in San Francisco online?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "<p>Yes, anyone with a driver's license can register to vote online.</p><p><a href=https://growsf.org/vote/>Click here</a> to get started.</p>"
            }
          }, {
            "@type": "Question",
            "name": "Can I register to vote without an ID?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "<p>Yes, you can register to vote even if you don't have a driver's license or state ID. You just need your social security number and to print & mail a form.</p><p><a href=https://growsf.org/vote/>Click here</a> to get started.</p>"
            }
          }]
        }`}
      </script>
    </Helmet>
  );
};
