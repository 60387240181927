import {
  PARTY,
  JURISDICTION,
  QS,
  ENDORSEMENT,
  ALPHABET,
  ENDORSEMENT_ORDER,
} from '../components/election/constants.js';
import { Contest, Candidate, Judge, Measure } from '../components/election/data_objs.js';

const defaultOrder = ALPHABET;
// Name ordering for November 2024 state contests
// https://www.sos.ca.gov/administration/news-releases-and-advisories/2024-news-releases-and-advisories/secretary-state-shirley-n-weber-phd-announces-results-randomized-alphabet-drawing-november-5-2024-general-election
const stateOrder = 'IFLTDEHQMJGAYRPCBVXKUNOWSZ';
// Name ordering for November 2024 local contests
// https://www.youtube.com/watch?v=gkvT9VK3OJE
const localOrder = 'NXQWGMBSVFORCKYLEUATDIJPHZ';

// These are listed in the order in which they'll appear on the ballot
export const nov24_contests = {
  // https://elections.cdn.sos.ca.gov//statewide-elections/2024-primary/cert-list-candidates.pdf
  President: Contest(
    JURISDICTION.FEDERAL,
    'President',
    [
      Candidate(
        'Joseph R Biden Jr',
        PARTY.DEM,
        'Incumbent President of the United States',
        QS.NOT_SENT
      ),
      Candidate('Donald J. Trump', PARTY.REP, 'Former President of the United States', QS.NOT_SENT),
      Candidate('Chase Oliver', PARTY.LIB, '', QS.NOT_SENT),
      Candidate('Robert F. Kennedy Jr.', PARTY.IND, '', QS.NOT_SENT),
      Candidate('Marianne Williamson', PARTY.DEM, '', QS.NOT_SENT),
    ],
    defaultOrder
  ),
  'US Congress, District 11': Contest(
    JURISDICTION.FEDERAL,
    'US Congress, District 11',
    [
      Candidate(
        { en: 'Nancy Pelosi', zh: '南希 ‧ 佩洛西' },
        PARTY.DEM,
        'Member of Congress; Incumbent',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Bruce Lou', zh: '樓成生' }, PARTY.REP, 'Business Owner', QS.NOT_SENT),
    ],
    defaultOrder
  ),
  'US Congress, District 15': Contest(
    JURISDICTION.FEDERAL,
    'US Congress, District 15',
    [
      Candidate(
        { en: 'Kevin Mullin', zh: '凱文 穆林' },
        PARTY.DEM,
        'U.S. Representative; Incumbent',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Anna Cheng Kramer', zh: '陳安娜' },
        PARTY.REP,
        'Housing Policy Advisor',
        QS.NOT_SENT
      ),
    ],
    defaultOrder
  ),
  'State Senate, District 11': Contest(
    JURISDICTION.STATE,
    { en: 'State Senate, District 11', zh: '州參議院，第 11 區' },
    [
      Candidate(
        { en: 'Scott Wiener', zh: '威善高' },
        PARTY.DEM,
        'State Senator; Incumbent',
        'https://drive.google.com/file/d/1CvHCB0xQI2li6s8C88lElthbesfLAOgt/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Yvette Corkrean', zh: '依薇特·科克安' },
        PARTY.REP,
        'Registered Nurse / Mother',
        'https://drive.google.com/file/d/1OLBQy3yFBnGanch1Ccv7AQ_EP8Qh_O9e/view?usp=drive_link'
      ),
    ],
    stateOrder
  ),
  'State Assembly, District 17': Contest(
    JURISDICTION.STATE,
    { en: 'State Assembly, District 17', zh: ' 17 區州眾議院，第17區' },
    [
      Candidate(
        { en: 'Matt Haney', zh: '楊馳馬' },
        PARTY.DEM,
        'Assemblymember; Incumbent',
        'https://drive.google.com/file/d/1ca76TqSGsVg_SCFo-tSx-XSIWJHfBEgy/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Manuel Noris-Barrera', zh: '李明杰' },
        PARTY.REP,
        'Real Estate Businessman',
        QS.NO_RESPONSE
      ),
    ],
    stateOrder
  ),
  'State Assembly, District 19': Contest(
    JURISDICTION.STATE,
    { en: 'State Assembly, District 19', zh: ' 19 區州眾議院，第17區' },
    [
      Candidate(
        { en: 'Catherine Stefani', zh: '司嘉怡' },
        PARTY.DEM,
        'Supervisor City and County of San Francisco, District 2',
        'https://drive.google.com/file/d/13nLy5Ldf47U8pz0r9BFAhuSAYCIZI5ab/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'David E. Lee', zh: '李志威' },
        PARTY.DEM,
        'Civil Rights Educator',
        QS.NO_RESPONSE
      ),
    ],
    stateOrder
  ),
  Mayor: Contest(
    JURISDICTION.LOCAL,
    {
      en: 'Mayor',
      es: 'Alcalde',
      fil: 'Mayor',
    },
    [
      Candidate(
        { en: 'London Breed', zh: '倫敦 ‧ 布里德' },
        PARTY.DEM,
        {
          en: 'Mayor of San Francisco; Incumbent',
          zh: '三藩市市長',
          es: 'Alcaldesa de San Francisco',
          fil: 'Alkalde ng San Francisco',
        },
        'https://drive.google.com/file/d/19TWzQf4Qccu3Kk4NyUOZHNabNTTkuAxe/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Ellen Lee Zhou', zh: '李愛晨' },
        PARTY.REP,
        {
          en: 'Family Social Worker',
          zh: '家庭社會工作者',
          es: 'Trabajadora social y familiar',
          fil: 'Social Worker para sa mga Pamilya',
        },
        'https://drive.google.com/file/d/1JTFc9fpicSW3EIC5NeZAeFLTKf0SzTyz/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Daniel Lurie', zh: '丹尼爾 ‧ 羅偉' },
        PARTY.DEM,
        {
          en: 'Nonprofit Executive',
          zh: '非營利組織高管',
          es: 'Ejecutivo sin fines de lucro',
          fil: 'Ehekutibo sa Non-profit',
        },
        'https://drive.google.com/file/d/1apBrHGloS9S_qOL6Lr7GGoGAzJv7DTGe/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Mark Farrell', zh: '麥法恩' },
        PARTY.DEM,
        {
          en: 'Small Business Owner; former Mayor of San Francisco',
          zh: '小企業主',
          es: 'Dueño de pequeño negocio',
          fil: 'May-ari ng Maliit na Negosyo',
        },
        'https://drive.google.com/file/d/1DFLiVs5fiSAAL_cBTfCrCjW-QMDPFSIC/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Aaron Peskin', zh: '佩斯金' },
        PARTY.DEM,
        {
          en: 'President, Board of Supervisors; Supervisor, District 3',
          zh: '市議會主席',
          es: 'Presidente, Consejo de Supervisores',
          fil: 'Presidente, Lupon ng mga Superbisor',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Ahsha Safaí', zh: '安世輝' },
        PARTY.DEM,
        {
          en: 'County Supervisor; District 11',
          zh: '縣議員',
          es: 'Supervisor del Condado',
          fil: 'Superbisor ng County',
        },
        QS.NO_RESPONSE
      ),
      // TODO: Check all parties
      Candidate(
        { en: 'Henry Flynn', zh: '亨利 ‧ 飛靈' },
        PARTY.NPP,
        {
          en: 'Security Specialist',
          zh: '保安專業人員',
          es: 'Especialista en seguridad',
          fil: 'Espesyalista sa Seguridad',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Keith Freedman', zh: '基思 ‧ 費里德曼' },
        PARTY.NPP,
        {
          en: 'Business Owner / Teacher',
          zh: '企業主 / 教師',
          es: 'Dueño de negocio / Maestro',
          fil: 'May-ari ng Negosyo/Guro',
        },
        'https://drive.google.com/file/d/1thAHWOZAizKi7YqFHqrvC6wlCzs49Y4K/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Dylan Hirsch-Shell', zh: '迪倫 ‧ 赫希-謝爾' },
        PARTY.NPP,
        {
          en: 'Engineer',
          zh: '工程師',
          es: 'Ingeniero',
          fil: 'Inihinyero',
        },
        'https://drive.google.com/file/d/1yY0VTgBXH6nzatvMP3PMEGPm9-EJWW6P/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Nelson Mei', zh: '梅燦斌 ' },
        PARTY.NPP,
        {
          en: 'Software Engineer',
          zh: '軟件工程師',
          es: 'Ingeniero de programas',
          fil: 'Inhinyero ng Software',
        },
        'https://drive.google.com/file/d/1u6H4FSNu5AylvDDt_sEstri-5ZwSS_gq/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Paul Ybarra Robertson', zh: '保羅 ‧ 伊巴拉 ‧ 羅伯森' },
        PARTY.NPP,
        {
          en: 'Self Employed',
          zh: '自僱人士',
          es: 'Empleado independiente',
          fil: 'Sarili ang Pagka-empleyo',
        },
        QS.NO_CONTACT
      ),
      Candidate(
        { en: 'Shahram Shariati', zh: '沙赫拉姆 ‧ 沙里亞提' },
        PARTY.NPP,
        {
          en: 'Civil / Transportation Engineer',
          zh: '民用 / 運輸工程師',
          es: 'Ingeniero civil y de transporte',
          fil: 'Inhinyerong Sibil / Pantransportasyon',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jon Ernest Soderstrom', zh: '約翰 ‧ 索德斯特螺慕' },
        PARTY.NPP,
        {
          en: 'None',
          zh: '沒有',
          es: 'Sin designación',
          fil: 'Wala',
        },
        QS.NO_RESPONSE
      ),
    ],
    localOrder,
    'Vote for all three, in any order',
    ENDORSEMENT_ORDER.RANDOM
  ),
  'District Attorney': Contest(
    JURISDICTION.LOCAL,
    {
      en: 'District Attorney',
      zh: '地方檢察官',
      es: 'Fiscal de Distrito',
      fil: 'Abugado ng Distrito',
    },
    [
      Candidate('Brooke Jenkins', PARTY.DEM, 'District Attorney; Incumbent', QS.NOT_SENT, true),
      Candidate('Ryan Khojasteh', PARTY.DEM, '', QS.NOT_SENT),
    ],
    localOrder
  ),
  'City Attorney': Contest(
    JURISDICTION.LOCAL,
    { en: 'City Attorney', es: 'Abogado de la Ciudad', fil: 'Abugado ng Lungsod' },
    [Candidate('David Chiu', PARTY.NP, 'City Attorney; Incumbent', QS.NOT_SENT, true)],
    localOrder
  ),
  Sheriff: Contest(
    JURISDICTION.LOCAL,
    { en: 'Sheriff', es: 'Alguacil', fil: 'Sheriff' },
    [
      Candidate('Paul Miyamoto', PARTY.NP, 'Sheriff; Incumbent', QS.NOT_SENT),
      Candidate('Michael Juan', PARTY.NP, '', QS.NOT_SENT),
    ],
    localOrder
  ),
  Treasurer: Contest(
    JURISDICTION.LOCAL,
    { en: 'Treasurer', es: 'Tesorero', fil: 'Ingat-yaman' },
    [Candidate('Jose Cisneros', PARTY.NP, 'Treasurer; Incumbent', QS.NOT_SENT)],
    localOrder
  ),
  'Supervisor, District 1': Contest(
    JURISDICTION.LOCAL,
    {
      en: 'Supervisor, District 1',
      zh: '市議員，第1選區',
      es: 'Junta de Supervisores, Distrito 1',
      fil: 'Lupon ng mga Superbisor, Distrito 1',
    },
    [
      Candidate(
        { en: 'Jeremiah Boehner', zh: '耶利米 ‧ 博納' },
        PARTY.NP,
        {
          en: 'Entrepreneur',
          zh: '企業家',
          es: 'Emprendedor',
          fil: 'Negosyante',
        },
        'https://drive.google.com/file/d/1rom9gHJ-R1NBLBOd_yt6TXF62yaoBEf4/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Connie Chan', zh: '陳詩敏' },
        PARTY.NP,
        {
          en: 'District 1 Supervisor; Incumbent',
          zh: '第1選區市議員',
          es: 'Supervisora del Distrito 1',
          fil: 'Superbisor ng Distrito 1',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: "Sherman D'Silva", zh: '薛曼 ‧ 狄西瓦' },
        PARTY.NP,
        {
          en: 'Operations Manager',
          zh: '營運經理',
          es: 'Gerente de operaciones',
          fil: 'Tagapamahala ng mga Operasyon',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jen Nossokoff', zh: '珍 ‧ 諾索科夫' },
        PARTY.NP,
        {
          en: 'Physician Assistant',
          zh: '醫生助理',
          es: 'Assistente médico',
          fil: 'Katuwang ng Doktor',
        },
        'https://drive.google.com/file/d/1GAux9-4bU7L2knVeKtXUJVbf6ffGoIYH/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Marjan Philhour', zh: '邁珍' },
        PARTY.NP,
        {
          en: 'Neighborhood Business Owner',
          zh: '鄰里企業主',
          es: 'Dueña de negocio local',
          fil: 'May-ari ng Negosyo sa Komunudad',
        },
        'https://drive.google.com/file/d/1Sta2f9RNzfSQK9YKaahn9trJsBNxw6p3/view?usp=drive_link',
        true
      ),
    ],
    localOrder
  ),
  'Supervisor, District 3': Contest(
    JURISDICTION.LOCAL,
    { en: 'Supervisor, District 3', zh: '市議員，第3選區' },
    [
      Candidate(
        { en: 'Wendy Ha Chau', zh: '周霞 ' },
        PARTY.NP,
        {
          en: 'Civil Rights Attorney',
          zh: '民權律師',
          es: 'Abogada de derecho civil',
          fil: 'Abugado para sa mga Karapatang Sibil',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Moe Jamil', zh: '麥 ‧ 讚明' },
        PARTY.NP,
        {
          en: 'Deputy City Attorney',
          zh: '副市府律師',
          es: 'Abogado de la Ciudad adjunto',
          fil: 'Katuwang ng Abugado ng Lungsod',
        },
        'https://drive.google.com/file/d/1kFpjMaIrqEeiMYqTzdTk1jjFccBqXWX1/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Sharon Lai', zh: '黎慧心' },
        PARTY.NP,
        {
          en: 'Director, Economic Non-profit',
          zh: '非營利經濟組織總監',
          es: 'Directora, organización económica sin fines de lucro',
          fil: 'Direktor ng Non-profit',
        },
        'https://drive.google.com/file/d/1Hzzho-0XCFEFmAjz981IamuOP3PQUSG3/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Eduard Navarro', zh: '愛德華 ‧ 納瓦羅' },
        PARTY.NP,
        {
          en: 'Urban Designer / Entrepreneur',
          zh: '城市設計師 / 企業家',
          es: 'Diseñador urbano / Emprendedor',
          fil: 'Taga-disenyo ng Lungsod / Negosyante',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Danny Sauter', zh: '李爾德' },
        PARTY.NP,
        {
          en: 'Neighborhood Center Director',
          zh: '鄰里中心總監',
          es: 'Director de centro local',
          fil: 'Direktor ng Sentro ng Komunidad',
        },
        'https://drive.google.com/file/d/1UMoyi4Bud7B6elaLsYDr4HC2lrUfIALV/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Matthew Susk', zh: '馬修 ‧ 蘇克' },
        PARTY.NP,
        {
          en: 'Businessman / Safety Director',
          zh: '商人 / 安全總監',
          es: 'Empresario / Director de seguridad',
          fil: 'Negosyante / Direktor para sa Kaligtasan',
        },
        'https://drive.google.com/file/d/1Tk5H3vGG6sds5HE_UNHkzUlD88L8wWsn/view?usp=drive_link'
      ),
    ],
    localOrder
  ),
  'Supervisor, District 5': Contest(
    JURISDICTION.LOCAL,
    { en: 'Supervisor, District 5', zh: '市議員，第5選區' },
    [
      Candidate(
        { en: 'Allen Jones', zh: '艾倫 ‧ 鍾斯' },
        PARTY.NP,
        {
          en: 'Draftsman',
          zh: '繪圖員',
          es: 'Dibujante',
          fil: 'Dibuhante',
        },
        'https://drive.google.com/file/d/1fWLqpKwX7y9Ajyv02JgesjZ1h5v9SAQp/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Autumn Looijen', zh: '洛珍' },
        PARTY.NP,
        {
          en: 'Community Organizer',
          zh: '社區組織者',
          es: 'Organizadora comunitaria',
          fil: 'Tagapag-organisa sa Komunidad',
        },
        'https://drive.google.com/file/d/13Mbhn4vpsgIAfp0vCOAS5NQAdOMULfgk/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Bilal Mahmood', zh: '馬百樂' },
        PARTY.NP,
        {
          en: 'Climate Nonprofit Director',
          zh: '非營利氣候組織總監',
          es: 'Director de organización climática sin fines de lucro',
          fil: 'Direktor ng Nonprofit na para sa Klima',
        },
        'https://drive.google.com/file/d/1LpdT4oZJys1rExDyoFogm9ufJUx5aTCg/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Dean Preston', zh: '潘正義' },
        PARTY.NP,
        {
          en: 'District 5 Supervisor; Incumbent',
          zh: '第5選區市議員',
          es: 'Supervisor del Distrito 5',
          fil: 'Superbisor ng Distrito 5',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Scotty Jacobs', zh: '史葛特 ‧ 雅各布斯' },
        PARTY.NP,
        {
          en: 'Private Sector Businessperson',
          zh: '私營部門商人',
          es: 'Empresario de sector privado',
          fil: 'Negosyante para sa Pribadong Sektor',
        },
        'https://drive.google.com/file/d/1PiBzzhCa_FH0ta6sxUWoVKSQSbXnvCWV/view?usp=drive_link'
      ),
    ],
    localOrder
  ),
  'Supervisor, District 7': Contest(
    JURISDICTION.LOCAL,
    { en: 'Supervisor, District 7', zh: '市議員，第7選區' },
    [
      Candidate(
        { en: 'Matt Boschetto', zh: '馬修 ‧ 博施基托' },
        PARTY.DEM,
        {
          en: 'Small Business Owner',
          zh: '小企業主',
          es: 'Dueño de pequeño negocio',
          fil: 'May-ari ng Maliit na Negosyo',
        },
        'https://drive.google.com/file/d/1aSrYI4cD3_LdAIxMsiL022QO0N2tNpg3/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Stephen Martin-Pinto', zh: '史蒂芬 ‧ 馬丁-品托' },
        PARTY.NP,
        {
          en: 'Firefighter / Military Reservist',
          zh: '消防員 / 預備役軍人',
          es: 'Bombero / Miembro de reserva del ejército',
          fil: 'Bumbero / Reserbista ng Militar',
        },
        'https://drive.google.com/file/d/1CkVZ6-xHH9pk0vV2mbds9OjQt9mFYQU6/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Myrna Melgar', zh: '梅義加' },
        PARTY.DEM,
        {
          en: 'District 7 Supervisor; Incumbent',
          zh: '第7選區市議員',
          es: 'Supervisora del Distrito 7',
          fil: 'Superbisor ng Distrito 7',
        },
        'https://drive.google.com/file/d/184bsB3KxQdCFUiuo6oSxczn375N057kp/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Edward Yee', zh: '余朝活' },
        PARTY.NP,
        {
          en: 'None',
          zh: '沒有',
          es: 'Sin designación',
          fil: 'Wala',
        },
        QS.NO_RESPONSE
      ),
    ],
    localOrder
  ),
  'Supervisor, District 9': Contest(
    JURISDICTION.LOCAL,
    { en: 'Supervisor, District 9', zh: '市議員，第9選區' },
    [
      Candidate(
        { en: 'Julian E. Bermudez', zh: '朱利安 ‧ 貝爾穆德斯' },
        PARTY.NP,
        {
          en: 'Businessman / Appliance Technician',
          zh: '商人／電器技術員',
          es: 'Empresario/ Técnico en electrodomésticos',
          fil: 'Negosyante/ Technician para sa mga Kasangkapan',
        },
        'https://drive.google.com/file/d/1oAV4KYR7GVU4pFmzh_R_BW1uQfCWoclO/view?usp=drive_link'
      ),
      Candidate(
        { en: 'H. Brown', zh: 'H ‧ 布朗' },
        PARTY.NP,
        {
          en: 'Retired Teacher',
          zh: '退休教師',
          es: 'Maestro jubilado',
          fil: 'Retiradong Guro',
        },
        'https://drive.google.com/file/d/1anDrTyzP13mA36aZiQ01G98KwQDRfMHy/view'
      ),
      Candidate(
        { en: 'Trevor L. Chandler', zh: '陳澤維' },
        PARTY.NP,
        {
          en: 'Public School Teacher',
          zh: '公立學校教師',
          es: 'Maestro de escuela pública',
          fil: 'Guro sa Pampublikong Paaralan',
        },
        'https://drive.google.com/file/d/12N-cKDUQtqz8jFSgjG8w_OrjXus1Txau/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Jackie Fielder', zh: '傑基 ‧ 菲爾德' },
        PARTY.NP,
        {
          en: 'Director, Climate Nonprofit',
          zh: '非營利氣候組織總監',
          es: 'Directora, organización climática sin fines de lucro',
          fil: 'Direktor, Non-profit na para sa Klima',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jaime Gutierrez', zh: '傑米 ‧ 古鐵雷斯 ' },
        PARTY.NP,
        {
          en: 'Transit Supervisor',
          zh: '運輸主管',
          es: 'Supervisor de tránsito',
          fil: 'Superbisor ng Pampublikong Transportasyon',
        },
        'https://drive.google.com/file/d/1KQDyh1oIolzh78S56pWK5Sct_QvGDMex/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Roberto Y. Hernandez', zh: '羅伯托 ‧ 赫南德斯' },
        PARTY.NP,
        {
          en: 'Nonprofit Executive Director',
          zh: '非營利組織行政總監',
          es: 'Director ejecutivo de organización sin fines de lucro',
          fil: 'Ehekutibong Direktor ng Nonprofit',
        },
        QS.DECLINED
      ),
      Candidate(
        { en: 'Stephen Torres', zh: '杜世衡' },
        PARTY.NP,
        {
          en: 'Hospitality Worker / Journalist',
          zh: '款待業工作者 / 記者',
          es: 'Trabajador hotelero / Periodista',
          fil: 'Nagtatrabaho sa Industriya ng Hospitalidad / Mamamahayag',
        },
        QS.NO_RESPONSE
      ),
    ],
    localOrder
  ),
  'Supervisor, District 11': Contest(
    JURISDICTION.LOCAL,
    { en: 'Supervisor, District 11', zh: '市議員，第11選區' },
    [
      Candidate(
        { en: 'Chyanne Chen', zh: '陳小焱' },
        PARTY.NP,
        {
          en: 'Parent / Non-profit Director',
          zh: '家長 / 非營利組織總監',
          es: 'Madre / Directora de organización sin fines de lucro',
          fil: 'Magulang/Direktor ng Non-Profit',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Adlah Chisti', zh: '雅德拉 ‧ 克里斯蒂' },
        PARTY.NP,
        {
          en: 'Policy Analyst / Planner',
          zh: '政策分析師 / 規劃師',
          es: 'Analista política / Planificadora',
          fil: 'Tagasuri ng Polisiya / Tagaplano',
        },
        'https://drive.google.com/file/d/1Ps_fgQttujs1Rqyc2NJasNMsU-xalzA5/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Oscar Flores', zh: '奧斯卡 ‧ 弗洛雷斯' },
        PARTY.NP,
        {
          en: 'Project Engineer',
          zh: '項目工程師',
          es: 'Ingeniero de proyecto',
          fil: 'Inhinyero ng Proyekto',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Ernest Jones', zh: '恩內斯特 ‧ "EJ" ‧ 鍾斯' },
        PARTY.NP,
        {
          en: 'Community Organizer',
          zh: '社區組織者',
          es: 'Organizador comunitario',
          fil: 'Tagapag-organisa sa Komunudad',
        },
        'https://drive.google.com/file/d/1vUUy8Rf804k6Aoh44U5Fc8MvpVASNDgv/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Michael Lai', zh: '賴天宸' },
        PARTY.NP,
        {
          en: 'Early Education Director',
          zh: '早期教育總監',
          es: 'Director de educación temprana',
          fil: 'Direktor ng Maagang Edukasyon',
        },
        'https://drive.google.com/file/d/1uVkKjcI8Rxe06Bnnc-rVV2Ahr6w5WxnV/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Roger Marenco', zh: '羅傑 ‧ K ‧ 馬倫科' },
        PARTY.NP,
        {
          en: 'Organizer / Transit Operator',
          zh: '組織者 / 運輸操作員',
          es: 'Organizador / Operador de tránsito',
          fil: 'Organisador / Nagpapatakbo sa Pampublikong Transportasyon',
        },
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jose Morales', zh: '何西 ‧ 莫拉雷斯' },
        PARTY.NP,
        {
          en: 'None',
          zh: '沒有',
          es: 'Sin designación',
          fil: 'Wala',
        },
        'https://drive.google.com/file/d/1h7obFQKulp_wO9DOCLhmYC4P-A87qDe0/view?usp=drive_link'
      ),
    ],
    localOrder
  ),
  'Board of Education': Contest(
    JURISDICTION.LOCAL,
    'Board of Education',
    [
      Candidate(
        'Matt Alexander',
        PARTY.NP,
        '',
        'https://drive.google.com/file/d/1B7G9VvizODYoRKukr4ez0Zm28fWSXeRL/view?usp=drive_link'
      ),
      Candidate(
        'Min Chang',
        PARTY.NP,
        '',
        'https://drive.google.com/file/d/1W259XjQxEPWGVzE6GhLkc4Maxnfk-dRr/view?usp=drive_link'
      ),
      Candidate(
        'Virginia Cheung',
        PARTY.NP,
        '',
        'https://drive.google.com/file/d/15ygI6l6cRYXOMOSAUFsAkoBiUBCnY9uM/view?usp=drive_link'
      ),
      Candidate(
        'Lefteris Eleftheriou',
        PARTY.NP,
        '',
        'https://drive.google.com/file/d/18xUSyMACYgkVGmrhCSD8o4T5t9Hnefp8/view?usp=drive_link'
      ),
      Candidate(
        'Parag Gupta',
        PARTY.NP,
        '',
        'https://drive.google.com/file/d/1yd6W-LItPnMNKEiD9HexGyfFUYzJPw2G/view?usp=drive_link',
        true
      ),
      Candidate(
        'Jaime Huling',
        PARTY.NP,
        '',
        'https://drive.google.com/file/d/1YumPlYLvylUBRLP9E5bqz42R2c9zPzh2/view?usp=drive_link',
        true
      ),
      Candidate(
        'John Jersin',
        PARTY.NP,
        '',
        'https://drive.google.com/file/d/18xaUki9Bfm_NH2RIBanPuyqMdPFXgTNr/view?usp=drive_link',
        true
      ),
      Candidate('Madeline Krantz', PARTY.NP, '', QS.NO_CONTACT),
      Candidate(
        'Laurance Lem Lee',
        PARTY.NP,
        '',
        'https://drive.google.com/file/d/1pw0R8Kb3AJP773socor6Vx8Kmvsq2jzp/view?usp=drive_link'
      ),
      Candidate('Deldelp Medina', PARTY.NP, '', QS.NO_RESPONSE),
      Candidate(
        'Supryia Marie Ray',
        PARTY.NP,
        '',
        'https://drive.google.com/file/d/1ESIFdcKzOv-NXkASt-w3627prJvMQTRE/view?usp=drive_link',
        true
      ),
      Candidate('Wendy Ha Chau', PARTY.NP, '', QS.NO_CONTACT),
    ],
    localOrder,
    'Vote for all 4'
  ),
  'Community College Board': Contest(
    JURISDICTION.LOCAL,
    'Community College Board',
    [
      Candidate('Rezwana "Aliya" Chisti', PARTY.NP, '', QS.NOT_SENT),
      Candidate('Ruth Ferguson', PARTY.NP, '', QS.NOT_SENT),
      Candidate('Leanna C. Louie', PARTY.NP, '', QS.NOT_SENT),
      Candidate('Heather McCarty', PARTY.NP, '', QS.NOT_SENT),
      Candidate('William Nocera', PARTY.NP, '', QS.NOT_SENT),
      Candidate('John Schneider', PARTY.NP, '', QS.NOT_SENT),
      Candidate('Alan Wong', PARTY.NP, '', QS.NOT_SENT),
      Candidate('Luis Zamora', PARTY.NP, '', QS.NOT_SENT),
    ],
    localOrder
  ),
};
