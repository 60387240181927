import { TLDR } from "../../../../src/components/election/tldr.js";
import { MeasureInfo } from "../../../../src/components/election/measure.js";
import { CandidateInfo, CandidateHeadshots, JudgeInfo } from "../../../../src/components/election/candidate_info.js";
import { VoterGuideFAQ } from "../../../../src/components/election/voter-guide-faq.js";
import BgWhiteWrapper from "../../../../src/components/election/bgWhiteWrapper.js";
import { nov24_contests } from "../../../../src/data/data-nov-2024.js";
import { nov24_d1_header, nov24_d3_header, nov24_d5_header, nov24_d7_header, nov24_d9_header, nov24_d11_header, nov24_positions_d1, nov24_positions_d3, nov24_positions_d5, nov24_positions_d7, nov24_positions_d9, nov24_positions_d11, nov24_mayor_header, nov24_mayor_tldr } from "../../../../content/voter-guide/issues.js";
import { RandomMayorList, RandomMayorString } from "../../../../content/voter-guide/helpers.js";
import { d7_myrna_questionnaires_header, d7_myrna_questionnaires_answers } from "../../../../content/voter-guide/issues.js";
import * as React from 'react';
export default {
  TLDR,
  MeasureInfo,
  CandidateInfo,
  CandidateHeadshots,
  JudgeInfo,
  VoterGuideFAQ,
  BgWhiteWrapper,
  nov24_contests,
  nov24_d1_header,
  nov24_d3_header,
  nov24_d5_header,
  nov24_d7_header,
  nov24_d9_header,
  nov24_d11_header,
  nov24_positions_d1,
  nov24_positions_d3,
  nov24_positions_d5,
  nov24_positions_d7,
  nov24_positions_d9,
  nov24_positions_d11,
  nov24_mayor_header,
  nov24_mayor_tldr,
  RandomMayorList,
  RandomMayorString,
  d7_myrna_questionnaires_header,
  d7_myrna_questionnaires_answers,
  React
};