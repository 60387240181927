import React, { Fragment, memo } from 'react';
import { PARTY, JURISDICTION, ENDORSEMENT, isNoEndorsement, isYes, isNo } from './constants';
//import { YesIcon } from '../helpers';

import YesIcon from '../icons/YesIcon';
import NoIcon from '../icons/NoIcon';

import { H2 } from '../layout/headings';
import { H3 } from '../layout/headings';
import { DESKTOP_HEADER_OFFSET, MOBILE_HEADER_OFFSET } from '../scrollSpy';

const TLDRSection = memo(
  ({
    hideCandidates = false,
    hideJudges = false,
    hideMeasures = false,
    jurisdiction,
    contests,
    endorsements = true,
    showJudges = true,
    lang = 'en',
  }) => {
    let candidates = Object.entries(contests).filter(([key, contest]) => contest.type == 'Contest');
    let judges = Object.entries(contests).filter(([key, contest]) => contest.type == 'Judge');
    let measures = Object.entries(contests).filter(([key, contest]) => contest.type == 'Measure');
    const classIDPrefix = jurisdiction.toLowerCase() + '-';

    const candidatesHeader = {
      en: 'Candidates',
      zh: '候選人',
      es: 'Candidatos',
      fil: 'Mga Kandidato',
    };

    const judgesHeader = {
      en: 'Judges',
      zh: '法官',
      es: 'Jueces',
      fil: 'Mga Hukom',
    };

    const propsHeader = {
      en: 'Ballot Measures',
      zh: '提案投票',
      es: 'Medidas en la Boleta',
      fil: 'Mga Panukala sa Balota',
    };

    return (
      <div className="flex flex-col gap-12">
        <div>
          <H3 hide={hideCandidates} idOverride={classIDPrefix + 'candidates'}>
            {candidatesHeader[lang]}
          </H3>
          <TwoColumn>
            {candidates.map(([key, val], index) => (
              <div key={index} className="group flex flex-col flex-nowrap break-inside-avoid">
                <Contest key={key} contest={val} endorsements={endorsements} lang={lang} />
              </div>
            ))}
          </TwoColumn>
        </div>
        {showJudges && judges.length > 0 ? (
          <div>
            <H3 hide={hideJudges} idOverride={classIDPrefix + 'judges'}>
              {judgesHeader[lang]}
            </H3>
            <TwoColumn>
              {judges.map(([key, val], index) => (
                <div key={index} className="group flex flex-col flex-nowrap break-inside-avoid">
                  <Contest key={key} contest={val} endorsements={endorsements} lang={lang} />
                </div>
              ))}
            </TwoColumn>
          </div>
        ) : null}
        {measures.length > 0 ? (
          <div>
            <H3 hide={hideMeasures} idOverride={classIDPrefix + 'measures'}>
              {propsHeader[lang]}
            </H3>
            <TwoColumn>
              {measures.map(([key, val], index) => (
                <div key={index} className="group flex flex-col flex-nowrap break-inside-avoid">
                  <Measure key={key} contest={val} endorsements={endorsements} lang={lang} />
                </div>
              ))}
            </TwoColumn>
          </div>
        ) : null}
      </div>
    );
  }
);

const Block = memo(({ link, children, lang = 'en' }) => {
  return (
    <button
      className="group not-prose text-left relative block break-inside-avoid no-underline py-3 rounded-lg my-0.5 outline-none"
      onClick={() => {
        window.history.replaceState({}, '', `#${link}`);
        window?.scrollTo({
          behavior: 'instant',
          top:
            document?.getElementById(link)?.getBoundingClientRect().top +
            window?.scrollY -
            (window?.innerWidth < 1024 ? MOBILE_HEADER_OFFSET : DESKTOP_HEADER_OFFSET),
        });
      }}
    >
      <span className="absolute w-[calc(100%_+_20px)] h-[calc(100%_+_4px)] -top-0.5 -left-2.5 rounded-lg pointer-events-none transform transition-all ease-in-out duration-100  group-hover:bg-gray-50 group-hover:border group-hover:border-slate-200 group-focus:bg-gray-50 group-focus:border group-focus:border-slate-200 sm:w-[calc(100%_+_40px)] sm:-left-5"></span>
      <span className="absolute w-[calc(100%_+_20px)] h-0.5 -top-1 -left-2.5 pointer-events-none bg-white opacity-0 group-hover:opacity-100 group-focus:opacity-100 sm:w-[calc(100%_+_40px)]"></span>
      <span className="absolute w-[calc(100%_+_20px)] h-0.5 -bottom-1 -left-2.5 pointer-events-none bg-white opacity-0 group-hover:opacity-100 group-focus:opacity-100 sm:w-[calc(100%_+_40px)]"></span>
      <div className="relative">{children}</div>
    </button>
  );
});

const Contest = memo(({ contest, endorsements = true, lang = 'en' }) => {
  const contestName = contest.names[lang] || contest.name;
  const link = `${contest.slug}`;
  const endorsement = contest.endorsement({ lang: lang });
  const annotation =
    contest.tldr_annotation[lang] || contest.tldr_annotation['en'] || contest.tldr_annotation;
  return (
    <Block link={link}>
      <h4 className="pb-2 uppercase text-brand-gray-4 text-xs tracking-widest">{contestName}</h4>
      {annotation && (
        <p className="text-black-800 border border-yellow-500 p-2 mb-3 font-semibold">
          ⚠️ {annotation}
        </p>
      )}
      <div className="flex flex-col gap-2">
        {endorsements && endorsement ? (
          typeof endorsement === 'string' ? (
            <div className="w-full flex items-center gap-1">
              {!isNoEndorsement(endorsement) && <YesIcon />}
              <p className="text-sm font-bold text-gray-700 sm:text-base">{endorsement}</p>
            </div>
          ) : (
            endorsement.map((endr, index) => (
              <div key={`endorsement_${index}`} className="w-full flex items-center gap-1">
                {!isNoEndorsement(endr) && <YesIcon />}
                <p className="text-sm font-bold text-gray-700 sm:text-base">{endr}</p>
              </div>
            ))
          )
        ) : null}
      </div>
    </Block>
  );
});

const Measure = memo(({ contest, endorsements = true, lang = 'en' }) => {
  const link = `prop-${contest.label}`.toLowerCase();
  const emoji = (endorsement) => {
    if (isYes(endorsement)) {
      return <YesIcon className="!m-0 !mr-2 inline" />;
    }
    if (isNo(endorsement)) {
      return <NoIcon className="!m-0 !mr-2 inline" />;
    }
    return '🤷🏾‍♀️';
  };

  let endorsement = contest.endorsement({ lang: lang });
  if (lang == 'en') {
    endorsement += ' on ';
  }
  endorsement = (
    <Fragment>
      {emoji(contest.endorsement({ lang: lang }))} {endorsement}
    </Fragment>
  );
  const propStr = {
    en: 'Proposition ',
    zh: '提案',
    es: 'Proposición ',
    fil: 'Proposisyon ',
  };

  let label = propStr[lang] + contest.label;
  const title = contest.names[lang] || contest.name;
  return (
    <Block link={link}>
      {endorsements && (
        <h4 className="text-sm font-bold text-brand-gray-5 sm:text-base">
          {endorsement}
          {label}
        </h4>
      )}
      <p className="text-gray-500 mt-2 text-xs font-normal sm:text-sm">{title}</p>
    </Block>
  );
});

const ThreeColumn = ({ children }) => {
  return (
    <div className="lg:-mx-40 md:-mx-10">
      <div className="space-y-2 sm:grid sm:grid-cols-3 md:gap-y-6 md:space-y-0">{children}</div>
    </div>
  );
};

const TwoColumn = ({ children }) => {
  return <div className="columns-1 sm:columns-2 sm:gap-x-12">{children}</div>;
};

const JumpDown = ({ id }) => {
  return (
    <div className="text-center flex flex-col gap-2.5 justify-start items-center mx-auto">
      <span className="text-gray-700 text-sm font-normal">
        Need more info? Jump down to the deep dive for a detailed explanation of our endorsement
      </span>
      <button
        className="flex justify-center items-center w-full px-5 py-3.5 mx-auto text-xs font-bold rounded-md uppercase bg-pos-0 scale-100 border border-brand-blue-4 text-brand-blue-4 bg-transparent transition duration-200 hover:bg-gradient-to-r hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:bg-size-200 hover:border-transparent hover:bg-pos-100 hover:text-white hover:scale-105 focus:bg-brand-blue-5 focus:border-white focus:text-white focus:bg-none focus:scale-100 sm:w-max"
        onClick={() => {
          window?.scrollTo({
            behavior: 'smooth',
            top:
              document?.getElementById(`${id}`)?.getBoundingClientRect().top +
              window?.scrollY -
              (window?.innerWidth < 1024 ? MOBILE_HEADER_OFFSET : 0),
          });
        }}
      >
        Jump down to learn more
      </button>
    </div>
  );
};

export const TLDR = memo(
  ({
    contests,
    endorsements = true,
    showFederal = true,
    showState = true,
    showLocal = true,
    showJudges = true,
    showOnly = null, // showOnly is a list of contest names (strings) to show. Nothing else will be shown.
    lang = 'en',
  }) => {
    function filter(ctsts, jurisdiction, showOnly = null) {
      let flt = Object.entries(ctsts).filter(([key, value]) => value.jurisdiction == jurisdiction);
      if (showOnly) {
        flt = flt.filter(([key, value]) => showOnly.includes(value.name));
      }
      return Object.fromEntries(flt);
    }
    const federal = filter(contests, JURISDICTION.FEDERAL, showOnly);
    const state = filter(contests, JURISDICTION.STATE, showOnly);
    const local = filter(contests, JURISDICTION.LOCAL, showOnly);
    const localHeader = {
      en: 'San Francisco',
      zh: '三藩市',
      es: 'San Francisco',
      fil: 'San Francisco',
    };

    const stateHeader = {
      en: 'California',
      zh: '州政府',
      es: 'California',
      fil: 'California',
    };

    const federalHeader = {
      en: 'Federal',
      zh: '聯邦政府',
      es: 'Federal',
      fil: 'Pederal',
    };

    return (
      <div className="prose relative flex flex-col justify-start gap-6 pb-10">
        {showLocal && Object.entries(local).length > 0 && (
          <Fragment>
            <div>
              <H2 hide={false} idOverride="tldr-local">
                {localHeader[lang]}
              </H2>
              <TLDRSection
                jurisdiction={JURISDICTION.LOCAL}
                contests={local}
                endorsements={endorsements}
                showJudges={showJudges}
                lang={lang}
                hideCandidates={false}
                hideJudges={false}
                hideMeasures={false}
              />
            </div>
            <JumpDown id="san-francisco" />
          </Fragment>
        )}
        {showState && Object.entries(state).length > 0 && (
          <Fragment>
            <div>
              <H2 hide={false} idOverride="tldr-state">
                {stateHeader[lang]}
              </H2>
              <TLDRSection
                jurisdiction={JURISDICTION.STATE}
                contests={state}
                endorsements={endorsements}
                lang={lang}
                hideCandidates={false}
                hideJudges={false}
                hideMeasures={false}
              />
            </div>
            <JumpDown id="state" />
          </Fragment>
        )}
        {showFederal && Object.entries(federal).length > 0 && (
          <Fragment>
            <div>
              <H2 hide={false} idOverride="tldr-federal">
                {federalHeader[lang]}
              </H2>
              <TLDRSection
                jurisdiction={JURISDICTION.FEDERAL}
                contests={federal}
                endorsements={endorsements}
                lang={lang}
                hideCandidates={false}
                hideJudges={false}
                hideMeasures={false}
              />
            </div>
            <JumpDown id="federal" />
          </Fragment>
        )}
      </div>
    );
  }
);

export default TLDR;
